<template>
  <router-link :to="value?.url" class="text-start p-2">
    <span class="regular-12 text-black">
      {{ value?.id }}
      <BaseIcon name="scanner" />
    </span>
  </router-link>
</template>

<script>
import BaseIcon from "../../icons/BaseIcon";
export default {
  name: "EventID",
  components: { BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
